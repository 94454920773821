/**
 * Get an array of Nodes based on a variety of inputs:
 * - Already an Array
 * - NodeList usually via document.query...
 * - A single HTMLElement Node
 * - a string treated as a query selector
 *
 * @param  {String} targets - see above
 * @return {Array}
 */
export default function getArrayOfNodes(targets) {
  var arr;
  if (Array.isArray(targets)) arr = targets;else if (targets instanceof NodeList) arr = Array.from(targets);else if (targets instanceof HTMLElement) arr = [targets];else if (typeof targets === 'string') arr = Array.from(document.querySelectorAll(targets));else throw new Error('Could not convert to array of nodes');
  return arr;
}