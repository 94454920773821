function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import Vendor from './components/Vendor';
import Toggler from './components/Toggler';
import AccordionBlock from './components/AccordionBlock';
import StatBlockCounters from './components/StatBlockCounters';
import SmoothScroller from './components/SmoothScroller';
// import ResponsiveDataTables from './components/ResponsiveDataTables'
import StickyFooter from './components/StickyFooter';
import Polyfills from './components/Polyfills';
import Hamburger from './components/Hamburger';
import Locator from './components/Locator';
import './fontawesome.js';
var useragent = require('ua-parser-js');
window.toKebabCase = function (str) {
  if (_typeof(str) != String) return str;
  return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(function (x) {
    return x.toLowerCase();
  }).join('-');
  return str.toLowerCase().replace(/\s+/g, '-');
}(function () {
  Polyfills.arrayFrom();
  var ua = useragent();
  Vendor.addUaToBody(ua);
  new Hamburger();
  // const mapEl = document.getElementById('locator-map')
  // if (mapEl) {
  // 	new Locator(mapEl)
  // }

  // create an active state toggler using defaults
  // const toggles = new Toggler('toggles', 'link', 'item', 'active')
  // toggles.activateFirstItem(true)

  // power-blocks/tabs
  var tabsBlocks = new Toggler('tabs', 'toggle', 'item');
  tabsBlocks.activateFirstItem(false);
  new AccordionBlock();
  new StatBlockCounters();

  // TODO mobile nav toggler

  //new StickyFooter()

  // auto scroll to hash links, ignoring classes
  var smoothScroll = new SmoothScroller(100, ['.toggles__link',
  // auto scroll with Toggler
  '.tabs__toggle', '[class*="popup--"]', '.c8-menu__link']);
  smoothScroll.scrollOnLoad();

  // statically call 3rd party methods
  Vendor.wpImageBlockModals();
  Vendor.wpGalleryBlockModals();
  // Vendor.choices('select')
  // Vendor.countUpJS()
  // Vendor.modalPopups()
  // Vendor.carousel()
  // Vendor.headroom()

  // create a responsive table
  // ! NOTE, only works with 1-tbody and requires a thead
  //let tblTest = new ResponsiveDataTables('.table--responsive')
  //tblTest.listenForResize()
  // tblTest.shrinkAll()
  // tblTest.shrink()
  // tblTest.expand()
  // tblTest.expandAll()
})();