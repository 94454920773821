import { dom, library } from '@fortawesome/fontawesome-svg-core';

// import specific icons here to keep the resource load smaller

// TODO: can i do
// import { * as light } from '@fortawesome/pro-light-svg-icons'
// library.add( light.faArrowDown,  ) etc?
// or would that not be as efficient?

import { faArrowRight, faAngleDown, faPlus, faMinus, faBars, faTimes, faArrowCircleDown } from '@fortawesome/pro-light-svg-icons';
import { faClock, faHandPointRight, faAngleLeft, faAngleRight, faBars as farBars, faTimes as farTimes, faAngleLeft as farAngleLeft, faAngleRight as farAngleRight, faCalculator, faArrowCircleDown as farArrowCircleDown } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAlt, faMapMarker, faCalendarAlt, faRedo, faShareSquare, faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faYoutube, faLinkedinIn, faPinterest, faHouzz } from '@fortawesome/free-brands-svg-icons';

// make imported icons available
library.add(
// light
faArrowRight, faAngleDown, faAngleLeft, faAngleRight, faPlus, faMinus, faBars, faTimes, faArrowCircleDown,
// regular
faClock, faHandPointRight, farBars, farTimes, farAngleLeft, farAngleRight, faCalculator, farArrowCircleDown,
// solid
faMapMarkerAlt, faMapMarker, faCalendarAlt, faRedo, faShareSquare, faCaretLeft, faCaretRight,
// brand
faFacebook, faTwitter, faInstagram, faYoutube, faLinkedinIn, faPinterest, faHouzz);

// replace <i>s with <svg>s
dom.watch();