import classnames from 'classnames';

/**
 * Create BEM classes with modifiers
 *
 * @param  {string} block   - BLOCK__element--mod
 * @param  {string} element - block__ELEMENT--mod
 * @param  {array}  mods    - block__element--MOD block__element--MOD2 (block__element)
 * @param  {string} additionalClasses - additional classes to add
 * @return {string} bem classes
 */
export default function bem() {
  var block = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var mods = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var additionalClasses = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var classes = [];
  var base = element ? "".concat(block, "__").concat(element) : block;
  classes.push(base);
  if (mods) {
    if (!Array.isArray(mods)) {
      mods = mods.split();
    }
    if (mods.length > 0) {
      mods.forEach(function (mod) {
        if (!mod) return;
        classes.push("".concat(base, "--").concat(mod));
      });
    }
  }
  return classnames(classes, additionalClasses);
}