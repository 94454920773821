// vendor styles
import 'reset-css/reset.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'magnific-popup/dist/magnific-popup.css';

// vendor scripts
import 'owl.carousel/dist/owl.carousel.min.js';

// frontend styles
import 'styles/main.scss';

// frontend scripts
import 'scripts/main.js';